const loadSidecart = async () => {
  try {
    if (cartBtn) {
      clearEls();
    }
    addOverlay();

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: Vuex } = await import(/* webpackChunkName: "vuex" */ 'vuex');

    const { BaseCart, CartStore, ConfigureStore, CheckoutStore } = await import(
      /* webpackChunkName: "sidecart_es" */ 'sidecart/dist/sidecart.es'
    );
    await import('sidecart/dist/style.css');

    CartStore.namespaced = true;
    ConfigureStore.namespaced = true;
    CheckoutStore.namespaced = true;

    Vue.use(Vuex);

    const store = new Vuex.Store({
      modules: {
        cart: CartStore,
        configure: ConfigureStore,
        checkout: CheckoutStore,
      },
    });

    const { i18n } = await import('../i18n');
    await import('core-js/stable');
    await import('regenerator-runtime/runtime');

    // Create our vue instance
    const vm = new Vue({
      i18n,
      el: '#cart',
      store,
      components: {
        'base-cart': BaseCart,
      },
    });
  } catch (e) {
    const sidecartLoader = document.getElementById('sidecart-loader');
    const sidecartLoadingSpinner = document.getElementById('sidecartLoadingSpinner');

    sidecartLoadingSpinner.innerHTML = 'Something went wrong. Click anywhere to close and try again.';
    sidecartLoadingSpinner.style.color = 'white';
    sidecartLoadingSpinner.style.fontSize = '18px';

    sidecartLoader.style.cursor = 'pointer';
    sidecartLoader.addEventListener('click', cancel);
  }
};

// Execute async function

const _forms = document.querySelectorAll('form');
const links = document.querySelectorAll("a[href*='addtocart=']");
const cartBtn = document.querySelector('#utility-nav-cart > a');

const urlParams = new URLSearchParams(window.location.search);
const addToCartPid = urlParams.get('addtocart');

const forms = [];
if (_forms) {
  for (const form of _forms) {
    const action = form.getAttribute('action');
    if (action && /\/add\.php$/.test(action)) {
      forms.push(form);
    }
  }
}

const cancel = () => {
  document.body.style.overflow = 'auto';
  document.body.style.maxHeight = 'none';
  const sidecartLoader = document.getElementById('sidecart-loader');
  sidecartLoader.remove();
  addListeners();
};

const clearEls = () => {
  forms.forEach((form) => {
    form.removeEventListener('submit', onFormClick);
  });
  links.forEach((link) => {
    link.removeEventListener('click', onLinkClick);
  });
  cartBtn.removeEventListener('click', onLinkClick);
};

const onFormClick = (e) => {
  e.preventDefault();
  e.currentTarget.getElementsByTagName('button')[0].setAttribute('sidecart-init', '1');
  loadSidecart();
};

const onLinkClick = (e) => {
  e.preventDefault();
  e.currentTarget.setAttribute('sidecart-init', '1');
  loadSidecart();
};

const showCheckout = () => {
  const segments = window.location.pathname.replace(/^\/+/, '').replace(/\/$/, '').split('/')
  if (!segments || !segments.length) {
    return false
  }
  return segments[0] === 'checkout'
}

const addListeners = () => {
  if (showCheckout()) {
    return loadSidecart();
  }
  forms.forEach((form) => {
    form.addEventListener('submit', onFormClick);
  });

  links.forEach((link) => {
    link.addEventListener('click', onLinkClick);
  });

  if (!cartBtn) {
    return;
  }
  cartBtn.addEventListener('click', onLinkClick);
};

const addOverlay = () => {
  var overlay = document.createElement('div');
  overlay.id = 'sidecart-loader';

  // Create loader SVG element
  var loader = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  loader.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  loader.setAttribute('width', '120');
  loader.setAttribute('height', '120');
  loader.setAttribute('viewBox', '0 0 32 32');

  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('d', 'M16,0C7.2,0,0,7.2,0,16h3.5C3.5,9.1,9.1,3.5,16,3.5V0z');
  path.setAttribute('fill', '#fff');

  var animateTransform = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
  animateTransform.setAttribute('accumulate', 'none');
  animateTransform.setAttribute('additive', 'replace');
  animateTransform.setAttribute('attributeName', 'transform');
  animateTransform.setAttribute('attributeType', 'xml');
  animateTransform.setAttribute('calcMode', 'linear');
  animateTransform.setAttribute('dur', '1.2s');
  animateTransform.setAttribute('fill', 'remove');
  animateTransform.setAttribute('from', '0 16 16');
  animateTransform.setAttribute('repeatCount', 'indefinite');
  animateTransform.setAttribute('restart', 'always');
  animateTransform.setAttribute('to', '360 16 16');
  animateTransform.setAttribute('type', 'rotate');

  var loaderHolder = document.createElement('div');
  loaderHolder.id = 'sidecartLoadingSpinner';
  loaderHolder.style.height = '120px';

  path.appendChild(animateTransform);
  loader.appendChild(path);
  loaderHolder.appendChild(loader);
  overlay.appendChild(loaderHolder);

  // Append overlay to the body
  document.body.appendChild(overlay);
  document.body.style.overflow = 'hidden';
  document.body.style.maxHeight = '100vh';

  // Apply styling
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.display = 'flex';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.zIndex = '9999';
  overlay.style.backgroundColor = 'rgba(4, 28, 44, 0.8)';
  overlay.style.webkitBackdropFilter = 'blur(8px)';
  overlay.style.backdropFilter = 'blur(8px)';
};

if (addToCartPid && !isNaN(addToCartPid)) {
  setTimeout(() => {
    loadSidecart();
  }, 0);
} else {
  addListeners();
}